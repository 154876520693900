import styled from 'styled-components';


const ToggleSwitch = ({ label, name, register }) => (
  <Wrapper>
    <Label htmlFor={name}>{label}</Label>
    <Switch>
      <Input type='checkbox' id={name} name={name} {...register(name)} />
      <Slider/>
    </Switch>
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Label = styled.label`
  font-weight: bold;
`;

const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 35px;
  height: 20px;
`;

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.15);
  transition: background-color .4s;
  border-radius: 17px;

  &::before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 3px;
    bottom: 3px;
    background-color: ${p => p.theme.textColor};
    transition: transform .4s;
    border-radius: 50%;
  }
`;

const Input = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + ${Slider} {
    background-color: ${p => p.theme.primary};
  }

  &:checked + ${Slider}::before {
    transform: translateX(15px);
  }
`;

export default ToggleSwitch;
