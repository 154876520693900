import { GlobalStoreState } from './main-store';

export const appLoadingSelector = (state: GlobalStoreState) => state.appLoading;
export const startAppSelector = (state: GlobalStoreState) => state.startApp;
/* AUTH STATE */
export const loggedInSelector = (state: GlobalStoreState) => state.loggedIn;
export const userSelector = (state: GlobalStoreState) => state.getUser();
export const loginSelector = (state: GlobalStoreState) => state.login;
export const logoutSelector = (state: GlobalStoreState) => state.logout;
export const initStateSelector = (state: GlobalStoreState) => state.initState;
export const fetchUserSelector = (state: GlobalStoreState) => state.fetchUser;
/* WALLPAPERS */
export const wallpaperSelector = (state: GlobalStoreState) => state.wallpaper;
/* STREAM SERVICE STATE */
export const availableStreamServicesSelector = (state: GlobalStoreState) => state.availableStreamServices;
export const streamServiceSelector = (state: GlobalStoreState) => state.getStreamService();
export const setStreamServiceSelector = (state: GlobalStoreState) => state.setStreamService;
export const getStreamServiceFromNameSelector = (state: GlobalStoreState) => state.getStreamServiceFromName;
/* HOME SELECTED LIST */
export const homeListIndexSelector = (state: GlobalStoreState) => state.homeListIndex;
export const setHomeListIndexSelector = (state: GlobalStoreState) => state.setHomeListIndex;
/* TOAST STATE */
export const toastDataSelector = (state: GlobalStoreState) => state.toastData;
export const toastIsVisibleSelector = (state: GlobalStoreState) => state.toastIsVisible;
export const showToastSelector = (state: GlobalStoreState) => state.showToast;
export const hideToastSelector = (state: GlobalStoreState) => state.hideToast;
/* DOWNLOAD STATE */
export const getDownloadSelector = (state: GlobalStoreState) => state.getDownload;
export const downloadsSelector = (state: GlobalStoreState) => state.getDownloads();
export const startDownloadSelector = (state: GlobalStoreState) => state.startDownload;
export const abortDownloadSelector = (state: GlobalStoreState) => state.abortDownload;
export const restartDownloadSelector = (state: GlobalStoreState) => state.restartDownload;
export const concurrentDownloadLimitSelector = (state: GlobalStoreState) => state.concurrentDownloadLimit;
export const setConcurrentDownloadLimitSelector = (state: GlobalStoreState) => state.setConcurrentDownloadLimit;
/* NOTIFICATION STATE */
export const notificationsSelector = (state: GlobalStoreState) => state.notifications;
export const animeLastNotificationMapSelector = (state: GlobalStoreState) => state.animeLastNotificationMap;
export const notificationLoadingSelector = (state: GlobalStoreState) => state.notificationLoading;
export const fetchNotificationsSelector = (state: GlobalStoreState) => state.fetchNotifications;
export const markNotificationsSelector = (state: GlobalStoreState) => state.markNotifications;
export const markAnimeEpNotificationSelector = (state: GlobalStoreState) => state.markAnimeEpNotification;
export const addNewNotificationsSelector = (state: GlobalStoreState) => state.addNewNotifications;
export const clearNotificationsSelector = (state: GlobalStoreState) => state.clearNotifications;
