import styled from 'styled-components';

const TextInput = ({ label, name, register, defaultValue, error, placeholder }) => (
  <div>
    <Label htmlFor={name}>{label}</Label>
    <Input id={name} name={name} defaultValue={defaultValue} {...register(name)} placeholder={placeholder} />
    {error?.message}
  </div>
);

const Label = styled.label`
  margin-top: 25px;
  margin-bottom: 7px;
  display: block;
  font-weight: bold;
`;

const Input = styled.input`
  padding: 10px 15px;
  display: block;
  width: 100%;
  border: none;
  border-radius: 4px;
  background-color: ${p => p.theme.dark3};
  color: ${p => p.theme.textColor};

  &:hover, &:focus-within {
    box-shadow: 0 0 0 2px ${p => p.theme.darkHover};
  }

  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
`;

export default TextInput;
