import { useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import HeaderActions from './header-actions';

import useModal from '../../components/modal/useModal';
import {
  useStore,
  streamServiceSelector,
  setStreamServiceSelector,
  availableStreamServicesSelector,
  logoutSelector,
  wallpaperSelector,
} from '../../store';

import Autocomplete from './Autocomplete';
import { createTriggerRender } from '../../components/button-dropdown/ButtonDropdown';
import NotificationMenu from '../../components/NotificationMenu';
import DropdownMenu from '../../components/DropdownMenu';
import { LastSeenModal, AdvancedSearchModal, DownloadManagerModal } from 'components/modals';
import IconAction from '../../components/IconAction';
import { HiddenMobile, EmptySpace, HiddenDesktop } from '../../components/basics';

import {ReactComponent as ManagerIcon} from 'assets/icons/download-manager-icon.svg';
import {ReactComponent as SettingsIcon} from 'assets/icons/settings-icon.svg';
import {ReactComponent as LogoutIcon} from 'assets/icons/logout-icon.svg';
import {ReactComponent as StreamServicesIcon} from 'assets/icons/stream-services-icon.svg';
import {ReactComponent as ListIcon} from 'assets/icons/stream-icon.svg';
import {ReactComponent as HistoryIcon} from 'assets/icons/history-icon.svg';
import {ReactComponent as AdvancedSearchIcon} from 'assets/icons/advanced-search-icon.svg';
import {ReactComponent as NewIcon} from 'assets/icons/new-icon.svg';

import { HeaderWrapper, AppName, SettingsInputContainer } from './Header.style';

function Header({ preview }) {
  const location = useLocation();
  const wallpaper = useStore(wallpaperSelector);
  const isAnimePage = location.pathname.startsWith('/anime/');
  return (
    <HeaderWrapper isTransparent={wallpaper !== undefined && isAnimePage}>
      <div style={{ display: 'flex', alignItems: 'center', gap: 15 }}>
        <img src='/logo.png' alt='site logo' width={35} height={35} />
        <HiddenMobile width={425}>
          <AppName to="/">AnimeStream</AppName>
        </HiddenMobile>
        <HiddenDesktop width={425}>
          <AppName to="/" style={{ fontWeight: 600 }}><i>AS</i></AppName>
        </HiddenDesktop>
      </div>
      {!preview && <HeaderLoggedSection />}
    </HeaderWrapper>
  );
}

function HeaderLoggedSection() {
  const navigate = useNavigate();
  const { visible, showModal, hideModal } = useModal();
  const { visible: searchVisible, showModal: showSearch, hideModal: hideSearch } = useModal();
  const { visible: lastSeenVisible, showModal: showLastSeen, hideModal: hideLastSeen } = useModal();
  const streamService = useStore(streamServiceSelector);
  const setStreamService = useStore(setStreamServiceSelector);
  const streamServices = useStore(availableStreamServicesSelector);
  const logout = useStore(logoutSelector);
  const catalogOptions = useRef([
    {
      leading: <NewIcon width='26' />,
      label: 'Aggiunti recentemente',
      value: { action: HeaderActions.LAST_EPISODES },
    },
    {
      leading: <HistoryIcon height='18' style={{ marginTop: -2 }} />,
      label: 'Ultimi visitati',
      value: { action: HeaderActions.LAST_REQUESTED },
    },
    {
      leading: <AdvancedSearchIcon height='20' style={{ marginTop: -2 }} />,
      label: 'Ricerca avanzata',
      value: { action: HeaderActions.ADVANCED_SEARCH },
    },
  ]);

  function onHeaderAction({ action, payload }) {
    switch(action) {
      case HeaderActions.LOGOUT: logout(); break;
      case HeaderActions.OPEN_DOWNLOAD_MANAGER: showModal(); break;
      case HeaderActions.CHANGE_SERVICE: setStreamService(payload); break;
      case HeaderActions.LAST_EPISODES: navigate(`/${streamService.id}/last-ep`); break;
      case HeaderActions.LAST_REQUESTED: showLastSeen(); break;
      case HeaderActions.ADVANCED_SEARCH: showSearch(); break;
      default: throw new Error('Unknown settings action');
    }
  }

  return (
    <>
      <DownloadManagerModal visible={visible} onOverlayClick={hideModal} />
      <AdvancedSearchModal visible={searchVisible} onOverlayClick={hideSearch} requestClose={hideSearch} />
      <LastSeenModal visible={lastSeenVisible} onOverlayClick={hideLastSeen} requestClose={hideLastSeen} />

      <Autocomplete streamService={streamService} />

      <SettingsInputContainer>
        <HiddenMobile width={1024}>
          <button onClick={showModal}>
            <IconAction icon={ManagerIcon} />
          </button>
          <DropdownMenu
            minWidth='267px'
            leadingWidth='32px'
            options={catalogOptions.current}
            renderTrigger={() => <IconAction icon={ListIcon} />}
            onSelect={onHeaderAction}
          />
        </HiddenMobile>

        <NotificationMenu />
        <HiddenDesktop>
          <div style={{ width: 10 }} />
        </HiddenDesktop>

        <DropdownMenu
          minWidth='280px'
          leadingWidth='27px'
          trailingWidth='27px'
          multiPage
          firstPageName='main'
          options={{
            main: [
              streamServices.length > 1  ? {
                leading: <StreamServicesIcon height='19' />,
                label: streamService.name,
                pageSelect: 'Servizi disponibili',
                hideDesktop: true,
                screenBreakpoint: 1024,
              } : null,
              {
                leading: <ListIcon height='19' />,
                label: 'Cataloghi',
                pageSelect: 'Cataloghi',
                hideDesktop: true,
                screenBreakpoint: 1024,
              },
              {
                leading: <ManagerIcon height='17' style={{ marginTop: -2 }} />,
                label: 'Download manager',
                value: { action: HeaderActions.OPEN_DOWNLOAD_MANAGER },
                hideDesktop: true,
                screenBreakpoint: 1024,
              },
              {
                leading: <LogoutIcon height='15' style={{ marginTop: -2 }} />,
                label: 'Esci',
                value: { action: HeaderActions.LOGOUT },
              },
            ].filter(p => p !== null),
            'Servizi disponibili': streamServices.map(ss => ({
              label: ss.name,
              value: { action: HeaderActions.CHANGE_SERVICE, payload: ss },
            })),
            'Cataloghi': catalogOptions.current,
          }}
          renderTrigger={() => <IconAction icon={SettingsIcon} />}
          onSelect={onHeaderAction}
        />

        <EmptySpace width='10px' />

        {streamServices.length > 1 && <HiddenMobile width={1024}>
          <DropdownMenu
            renderTrigger={renderStreamServiceTrigger}
            minWidth='17ch'
            buttonValue={streamService.name}
            options={streamServices.map(ss => ({ label: ss.name }))}
            onSelect={(name) => {
              setStreamService(streamServices.find(ss => ss.name === name));
              navigate('/');
            }}
          />
        </HiddenMobile>}
      </SettingsInputContainer>
    </>
  );
}

const renderStreamServiceTrigger = createTriggerRender({ width: '17ch' });

export default Header;
