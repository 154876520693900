import { StoreApi } from 'zustand';
import Api, { NotificationType, UserNotification } from '../api';
import { GlobalStoreState } from './main-store';

export type AnimeLastNotification = { [key: number]: { [key: string]: UserNotification } };

export interface StoreNotificationState {
  notifications: UserNotification[];
  animeLastNotificationMap: AnimeLastNotification;
  notificationLoading: boolean;
  fetchNotifications: () => Promise<void>;
  markNotifications: (notification: UserNotification) => Promise<void>;
  markAnimeEpNotification: (animeId: string, ss: number, episode: number) => Promise<void>;
  addNewNotifications: (notifications: UserNotification[]) => void;
  clearNotifications: () => void;
}

function createNotificationState(set: StoreApi<GlobalStoreState>['setState'], get: StoreApi<GlobalStoreState>['getState']): StoreNotificationState {
  const getAnimeLastNotificationMap = (nots: UserNotification[]) => {
    const result: AnimeLastNotification = {};

    nots.forEach((n) => {
      if (n.notification.type === NotificationType.EPISODE) {
        const { body: { ss, remote_id }, created_at } = n.notification;

        // create ss map if does not exists
        if (!result[ss]) result[ss] = {};

        const oldCreatedAt = result[ss][remote_id]?.notification?.created_at;
        if (!oldCreatedAt || created_at > oldCreatedAt)
          result[ss][remote_id] = n;
      }
    });

    return result;
  };

  const setNotifications = (nots: UserNotification[]) => {
    set({ notifications: nots, animeLastNotificationMap: getAnimeLastNotificationMap(nots) });
  };

  return {
    notifications: [],
    animeLastNotificationMap: {},
    notificationLoading: true,
    fetchNotifications: async () => {
      if (!get().notificationLoading) set({ notificationLoading: true });
      try {
        const data = await Api.getNotifications();
        setNotifications(data);
      } finally {
        set({ notificationLoading: false });
      }
    },
    markNotifications: async (un) => {
      const oldData = get().notifications;
      const newData = oldData.map(oun => oun.notification.id === un.notification.id ? { ...oun, seen: true } : oun);
      setNotifications(newData);
      await Api.markNotification(un.notification.id);
    },
    markAnimeEpNotification: async (animeId, ss, episode) => {
      const { notifications } = get();
      const n = notifications.find(({ notification: { body } }) => (
        body.remote_id === animeId && body.ss === ss && body.episode === episode
      ));
      if (n !== undefined) {
        n.seen = true;
        setNotifications([...notifications]);
        await Api.markNotification(n.notification.id);
      }
    },
    addNewNotifications: (notifications) => setNotifications([...notifications, ...get().notifications]),
    clearNotifications: () => setNotifications([]),
  };
}

export default createNotificationState;
