export const ORDER_BY = {
  'Nome': 'name',
  'Voto': 'rate',
  'Recenti': 'recent',
  'Anno': 'date',
}

export const GENRES: { [key: string]: { [key: string]: string | null } } = {
  HW: {
    'Nessuno': null,
    'Ahegao': 'ahegao',
    'Anal': 'anal',
    'BDSM': 'bdsm',
    'Big Boobs': 'big-boobs',
    'Comedy': 'comedy',
    'Cosplay': 'cosplay',
    'Creampie': 'creampie',
    'Dark Skin': 'dark-skin',
    'Erotic Game': 'erotic-game',
    'Facial': 'facial',
    'Fantasy': 'fantasy',
    'Foot Job': 'foot-job',
    'Gangband': 'gangbang',
    'Glasses': 'glasses',
    'Hand Job': 'hand-job',
    'Horror': 'horror',
    'Incest': 'incest',
    'Lactation': 'lactation',
    'Masturbation': 'masturbation',
    'Milf': 'milf',
    'Mind Break': 'mind-break',
    'Monster': 'monster',
    'Orgy': 'orgy',
    'Plot': 'plot',
    'POV': 'pov',
    'Pregnant': 'pregnant',
    'Public Sex': 'public-sex',
    'Rape': 'rape',
    'Reverse Rape': 'reverse-rape',
    'School Girl': 'school-girl',
    'Softcore': 'softcore',
    'Tentacle': 'tentacle',
    'Threesome': 'threesome',
  },
  default: {
    'Nessuno': null,
    'Arti Marziali': 'martial-arts',
    'Avventura': 'adventure',
    'Azione': 'action',
    'Commedia': 'comedy',
    'Demenziale': 'demential',
    'Drammatico': 'drama',
    'Ecchi': 'ecchi',
    'Fantasy': 'fantasy',
    'Harem': 'harem',
    'Hentai': 'hentai',
    'Horror': 'horror',
    'Josei': 'josei',
    'Magia': 'magic',
    'Macha': 'macha',
    'Mistero': 'mystery',
    'Musica': 'music',
    'Parodia': 'parody',
    'Psicologico': 'psychological',
    'Samurai': 'samurai',
    'Scolastico': 'school',
    'Sentimentale': 'sentimental',
    'Shojo': 'shojo',
    'Shonen': 'shonen',
    'Slice of Life': 'slice-of-life',
    'Soprannaturale': 'supernatural',
    'Spazio': 'space',
    'Sport': 'sport',
    'Storico': 'historical',
    'Superpoteri': 'superpowers',
    'Vampiri': 'vampires',
    'Yaoi': 'yaoi',
    'Yuri': 'yuri',
  },
};

export const GENRES_LABEL_MAP = Object.fromEntries(Object
  .entries({...GENRES.HW, ...GENRES.default})
  .map(([k, v]) => [v, k])
);
