import { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { FullUserAnime } from 'api';
import { useStore, streamServiceSelector } from 'store';
import useLastSeen from 'queries-hooks/useLastSeen';

import Modal from '../modal';
import CircularLoader from '../loaders/CircularLoader';
import { ModalContainer } from '../basics';

import {ReactComponent as FavoriteBorderIcon} from 'assets/icons/favorite-border-icon.svg';
import {ReactComponent as FavoriteFillIcon} from 'assets/icons/favorite-icon.svg';

export interface LastSeenModalProps {
  visible: boolean;
  onOverlayClick: () => void;
  requestClose: () => void;
}

function LastSeenModal({ visible, onOverlayClick, requestClose }: LastSeenModalProps) {
  return (
    <Modal visible={visible} onOverlayClick={onOverlayClick} unmountOnExit={false}>
      <ModalContainer maxWidth='550px' maxHeight='550px'>
        <LastSeen requestClose={requestClose} visible={visible} />
      </ModalContainer>
    </Modal>
  );
}

interface LastSeenProps {
  visible: boolean;
  requestClose: () => void;
}

function LastSeen({ visible, requestClose }: LastSeenProps) {
  const navigate = useNavigate();
  const streamService = useStore(streamServiceSelector);
  const { data: animeList, loading, fetchData: fetch } = useLastSeen();

  const onAnimeClick = useCallback((ua: FullUserAnime) => {
    navigate(`/anime/${ua.anime.ss}/${ua.anime.remote_id}`);
    requestClose();
  }, [requestClose, navigate]);

  useEffect(() => {
    if (visible) fetch(streamService.id);
  }, [visible, fetch, streamService]);

  return (
    <>
      <Title>Ultimi Visitati</Title>
      <ListContainer>
        {!loading && animeList ? animeList.slice(0, 5).map((ua, i) => (
          <AnimeButton key={ua.id} type='button' onClick={() => onAnimeClick(ua)}>
            <IndexIndicator>{i+1}</IndexIndicator>
            <AnimeTitle>{ua.anime.title}</AnimeTitle>
            <FavoriteContainer>
              {ua.favorite ? <FavoriteFillIcon /> : <FavoriteBorderIcon />}
            </FavoriteContainer>
          </AnimeButton>
        )) : <DataLoaderIndicator />}
      </ListContainer>
    </>
  );
}

const DataLoaderIndicator = () => <LoaderContainer><CircularLoader color='white' /></LoaderContainer>;

const Title = styled.h2`
  text-align: center;
  margin: 10px 0;
`;

const ListContainer = styled.div`
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const AnimeButton = styled.button`
  display: flex;
  align-items: center;
  border-radius: 6px;
  padding: 0.75rem 1rem;
  margin: 5px 0;
  text-align: left;
  font-size: 16px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.075);
  }

  & .svg-path {
    fill: ${p => p.theme.textMuted};
  }
`;

const IndexIndicator = styled.p`
  font-weight: bold;
  flex: 0 0 3em;
  font-size: 12px;

  @media (max-width: 768px) {
    flex: 0 0 2em;
  }
`;

const AnimeTitle = styled.p`
  flex: 1 1 auto;
  font-size: 14px;
`;

const FavoriteContainer = styled.div`
  flex: 0 0 24px;
  height: 24px;
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 35px 0;
`;

export default LastSeenModal;
