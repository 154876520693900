import RamCache from '../services/cache.service';
import Api from './api';
import { FullUserAnime, VideoUrls } from './interfaces';

class CachedApi extends Api {
  private animePrefetchFlag = true;
  private animeCache = new RamCache<Promise<FullUserAnime>>();
  private epCache = new RamCache<Promise<VideoUrls>>();

  public async preFetchAnimeAndVideo(ss: number, animeId: string): Promise<void> {
    if (this.animePrefetchFlag) {
      this.animePrefetchFlag = false;
      // prefetch anime
      const animePromise = super.getAnime(ss, animeId);
      const animeKey = `${ss}|${animeId}`;
      this.animeCache.set(animeKey, animePromise);

      // prefetch episode
      const anime = await animePromise;
      const { last_seen_season, last_seen_episode, anime: { seasons } } = anime;
      const ep = seasons[last_seen_season].episodes[last_seen_episode];
      // if there is altleast one episode
      if (ep) {
        const episodeKey = `${ss}|${ep.url}`;
        const epUrlsPromise = super.getEpisodeVideoUrls(ss, ep.url);
        this.epCache.set(episodeKey, epUrlsPromise);
      }
    }
  }

  // ANNOTATION: Override
  public async getAnime(ss: number, id: string): Promise<FullUserAnime> {
    const key = `${ss}|${id}`;
    const res = this.animeCache.pop(key);
    return res || super.getAnime(ss, id);
  }

  public async preFetchEpisodeVideoUrl(ss: number, episodeUrl: string): Promise<void> {
    const key = `${ss}|${episodeUrl}`;
    if (!this.epCache.has(key)) {
      const req = super.getEpisodeVideoUrls(ss, episodeUrl);
      this.epCache.set(key, req);
    }
  }

  // ANNOTATION: Override
  public async getEpisodeVideoUrls(ss: number, episodeUrl: string): Promise<VideoUrls> {
    const key = `${ss}|${episodeUrl}`;
    const req = this.epCache.pop(key);
    return req || super.getEpisodeVideoUrls(ss, episodeUrl);
  }
}

export default CachedApi;
