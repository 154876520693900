import styled, { css, keyframes } from 'styled-components';

export const Container = styled.div<{ xl?: boolean }>`
  width: 100%;
  margin: 0 auto;

  @media (min-width: 640px) {
    max-width: 640px;
  }

  @media (min-width: 768px) {
    max-width: 768px;
  }

  @media (min-width: 1024px) {
    max-width: 1024px;
  }

  @media (min-width: 1280px) {
    max-width: 1280px;
  }

  ${p => p.xl && css`
    @media (min-width: 1450px) {
      max-width: 1450px;
    }
  `}
`;

export interface CardProps {
  title?: string;
  className?: string;
  children: React.ReactNode;
}

export const Card = ({ title, className, children }: CardProps) => (
  <CardContainer className={className}>
    {title && <CardTitle>{title}</CardTitle>}
    {children}
  </CardContainer>
); 

const CardContainer = styled.div`
  background-color: ${p => p.theme.dark3};
  border-radius: 8px;
  padding: 25px 35px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    padding: 25px 20px;
  }
`;

const CardTitle = styled.h3`
  font-size: 18px;
  margin-bottom: 30px;
  @media (max-width: 768px) {
    margin-bottom: 15px;
  }
`;

export const HiddenMobile = styled.div<{ width?: number }>`
  display: none;
  @media (min-width: ${p => p.width || 768}px) {
    display: contents;
  }
`;

export const HiddenDesktop = styled.div<{ width?: number }>`
  display: contents;
  @media (min-width: ${p => p.width || 768}px) {
    display: none;
  }
`;

export const EmptySpace = styled.div<{ width?: string, height?: string }>`
  height: ${p => p.height || 0};
  width: ${p => p.width || 0};
`;

export const TextMuted = styled.p`
  color: #7e868d;
  font-weight: 600;
  font-size: 14px;
`;

export const ModalContainer = styled.div<{ maxWidth?: string, maxHeight?: string, bg?: string, radius?: string }>`
  position: absolute;
  top: 130px;
  left: 50%;
  transform: translate(-50%);
  padding: 20px 30px;
  background-color: ${p => p.bg || p.theme.dark4};
  width: ${p => p.maxWidth || '600px'};
  border-radius: ${p => p.radius || '12px'};
  overflow-y: auto;
  
  ${p => p.maxHeight && css<{ maxHeight?: string }>`
    max-height: ${p => p.maxHeight};
  `}

  @media (max-width: 768px) {
    width: 350px;
    padding: 15px 25px;
  }

  @media (max-width: 375px) {
    width: 300px;
    padding: 15px;
  }
`;

export const Divider = styled.hr<{ mx?: number, my?: number }>`
  border: none;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.125);
  margin: ${p => `${p.my || 0}px ${p.mx || 0}px`};
  padding: 0;
`;

export interface ButtonProps {
  mx?: number;
  my?: number;
  px?: number;
  py?: number;
  width?: number;
  height?: number;
  bg?: string;
  hoverBg?: string;
}
export const Button = styled.button.attrs<ButtonProps>({ type: 'button' })<ButtonProps>`
  margin: ${p => `${p.my || 0}px ${p.mx || 0}px`};
  padding: ${p => `${p.py || 0}px ${p.px || 0}px`};
  width: ${p => p.width ? `${p.width}px` : 'auto'};
  height: ${p => p.height ? `${p.height}px` : 'auto'};
  background-color: ${p => p.bg || 'rgba(255, 255, 255, 0.05)'};
  border-radius: 4px;
  transition: background-color 250ms;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${p => p.theme.textColor};

  &:hover {
    background-color: ${p => p.hoverBg || 'rgba(255, 255, 255, 0.1)'};
  }

  &:disabled {
    opacity: 1;
  }
`;

export const PingIndicator = () => (
  <PingContainer>
    <PingDynamicSpan style={{ backgroundColor: '#f59f00' }} />
    <span style={{ backgroundColor: '#e67700' }} />
  </PingContainer>
);

const PingContainer = styled.span`
  position: absolute;
  top: -3px;
  right: 5px;

  & span {
    position: absolute;
    display: inline-flex;
    width: 10px;
    height: 10px;
    border-radius: 6px;
  }
`;

const pingKeyframes = keyframes`
  75%, 100% {
    transform: scale(2);
    opacity: 0;
  }
`;

const PingDynamicSpan = styled.span`
  opacity: 0.75;
  animation: ${pingKeyframes} 1s cubic-bezier(0,0,.2,1) infinite;
`;

export const Badge = styled.div`
  padding: 4px 6px;
  border-radius: 2px;
  background-color: ${p => p.theme.primaryDark};
  color: #fff;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0.7px;
`;
