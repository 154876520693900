import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { markNotificationsSelector, notificationLoadingSelector, notificationsSelector, useStore } from 'store';
import { UserNotification } from 'api';
import Modal, { useModal } from '../modal';
import { ModalContainer } from '../basics';
import { Loader, renderTrigger } from './common';
import EpisodeNotificationUI from './EpisodeNotificationUI';

const MobileNotificationMenu = React.memo(() => {
  const { visible, showModal, hideModal } = useModal();
  const notifications = useStore(notificationsSelector);
  const loading = useStore(notificationLoadingSelector);
  const markNotification = useStore(markNotificationsSelector);
  const unseenCount = useMemo(() => {
    return notifications.reduce((acc, element) => acc + (element.seen ? 0 : 1), 0);
  }, [notifications]);

  const onNotification = useCallback((notification: UserNotification) => {
    if (!notification.seen) markNotification(notification);
    hideModal();
  }, [markNotification, hideModal]);

  return (
    <>
      <button onClick={showModal}>
        {renderTrigger({ value: ''+unseenCount })}
      </button>
      <Modal visible={visible} onOverlayClick={hideModal}>
        <ModalContainer maxHeight='550px' className='scrollable' radius='6px'>
          <Modal.FadeIn>
            <>
              <h2 style={{ marginBottom: 20 }}>Notifiche</h2>
              {loading ? <Loader /> : (
                <>
                  {notifications.map(n => (
                    <EpisodeNotificationUI key={n.notification.id} notification={n} onNotification={onNotification} />
                  ))}
                  {notifications.length === 0 && <MutedText>Non hai nessuna notifica</MutedText>}
                </>
              )}
            </>
          </Modal.FadeIn>
        </ModalContainer>
      </Modal>
    </>
  );
});

const MutedText = styled.p`
  color: #ddd;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  margin-top: 35px;
  margin-bottom: 20px;
`;

export default MobileNotificationMenu;
