import React from 'react';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';
import './style.css';

interface DropdownProps {
  position?: 'top' | 'bot';
  visible: boolean;
  containerTransform?: string;
  children: React.ReactNode;
}

function Dropdown({ visible, containerTransform, position='bot', children }: DropdownProps) {
  const nodeRef = React.useRef(null);
  return (
    <CSSTransition
      nodeRef={nodeRef}
      in={visible}
      timeout={150}
      classNames='dropdown'
      mountOnEnter={true}
      unmountOnExit={true}
    >
      <div
        ref={nodeRef}
        className='dropdown-wrapper'
        style={position === 'top' ? { top: 0 } : { bottom: 0 }}
      >
        <Container
          style={position === 'top' ? {
            transform: `${containerTransform || ''} translateY(-100%)`,
            top: '-.6rem',
          } : {
            transform: containerTransform,
            top: '.6rem',
          }}
        >
          {children}
        </Container>
      </div>
    </CSSTransition>
  );
}

const Container = styled.div`
  position: absolute;
  right: 0;
  min-width: 100%;
`;

export default Dropdown;
