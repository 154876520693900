import { useState, useCallback } from 'react';
import Menu, { MenuProps } from './Menu';
import ButtonDropdown from './button-dropdown/ButtonDropdown';

export interface DropdownMenuProps extends MenuProps {
  buttonValue?: string;
  renderTrigger?: (data: { value?: string }) => JSX.Element;
  disabled?: boolean;
  position?: 'top' | 'bot';
  containerTransform?: string;
}

const DropdownMenu = ({
  buttonValue,
  renderTrigger,
  type,
  options=[],
  onSelect,
  maxHeight='unset',
  minWidth='unset',
  leadingWidth,
  trailingWidth,
  multiPage,
  firstPageName='index',
  background,
  disabled,
  position,
  containerTransform,
}: DropdownMenuProps) => {
  const [visible, setVisible] = useState(false);
  
  const innerOnSelect = useCallback((value: string, index: number) => {
    onSelect(value, index);
    setVisible(false);
  }, [onSelect]);

  return (
    <ButtonDropdown value={buttonValue} renderTrigger={renderTrigger} visible={visible} setVisible={setVisible} disabled={disabled} position={position} containerTransform={containerTransform}>
      <Menu
        type={type}
        options={options}
        onSelect={innerOnSelect}
        maxHeight={maxHeight}
        minWidth={minWidth}
        leadingWidth={leadingWidth}
        trailingWidth={trailingWidth}
        multiPage={multiPage}
        firstPageName={firstPageName}
        background={background}
      />
    </ButtonDropdown>
  );
};

export default DropdownMenu;
