import create from 'zustand';
import Api, { User } from 'api';
import StorageService, { StreamService } from 'services/storage.service';
import createToastState, { StoreToastState } from './toast-state';
import createDownloadState, { StoreDownloadState } from './download-state';
import createNotificationState, { StoreNotificationState } from './notification-state';

export type HomeListIndexType = 1 | 2 | 3;

export interface WallpaperOptions {
  src: string;
  blurAmt?: number;
}

export interface GlobalStoreState extends StoreDownloadState, StoreToastState, StoreNotificationState {
  appLoading: boolean;
  startApp: () => Promise<void>;
  /* AUTH STATE */
  loggedIn: boolean;
  user?: User;
  getUser: () => User;
  login: (username: string, password: string) => Promise<void>;
  logout: (feedback?: boolean) => void;
  initState: (user: User, availableStreamServices: StreamService[]) => void;
  fetchUser: () => Promise<void>;
  /* STREAM SERVICE STATE */
  availableStreamServices: StreamService[];
  streamService?: StreamService;
  getStreamService: () => StreamService;
  setStreamService: (newStreamService: StreamService) => void;
  getStreamServiceFromName: (name: string) => StreamService;
  /* HOME SELECTED LIST */
  homeListIndex: HomeListIndexType;
  setHomeListIndex: (newIndex: HomeListIndexType) => void;
  /* WALLPAPERS */
  wallpaper?: WallpaperOptions;
}

function throwIfUndefined<T>(item: T, errMsg: string): Exclude<T, undefined> {
  if (item !== undefined) return item as Exclude<T, undefined>;
  throw new Error(errMsg);
}

const useStore = create<GlobalStoreState>((set, get) => ({
  appLoading: true,
  startApp: async () => {
    const token = StorageService.getToken();
    try {
      if (!!token) {
        Api.addToken(token);
        const { user, services } = await Api.getServerInfo();
        get().initState(user, services);
        set({ appLoading: false });

        setTimeout(() => {
          get().fetchNotifications().catch(() => console.warn('Unable to fetch Notifications'));
        }, 500);
      }
    } catch (_) {
      Api.removeToken();
    } finally {
      set({ appLoading: false });
    }
  },
  /* AUTH STATE */
  loggedIn: false,
  user: undefined,
  getUser: () => throwIfUndefined(get().user, 'User state is not defined'),
  login: async (username, password) => {
    const { token, user, services } = await Api.login(username, password);
    const { initState } = get();

    StorageService.setToken(token);
    Api.addToken(token);
    initState(user, services);
    get().fetchNotifications().catch(() => console.warn('Unable to fetch Notifications'));
  },
  logout: (feedback=false) => {
    if (feedback) {
      const { showToast } = get();
      showToast({ msg: "L'autentificazione è scaduta!" }, 3000);
    }
    StorageService.removeToken();
    Api.removeToken();
    set({ user: undefined, availableStreamServices: [], loggedIn: false, notifications: [] });
  },
  initState: (user, availableStreamServices) => {
    const storageService = StorageService.getSelectedStream();
    let streamService;
    if (storageService === null) {
      streamService = availableStreamServices[0];
    } else {
      const index = availableStreamServices.findIndex(ss => ss.id === storageService.id);
      streamService = index >= 0 ? storageService : availableStreamServices[0];
    }
    set({ user, availableStreamServices, loggedIn: true, streamService });
  },
  fetchUser: async () => {
    const data = await Api.getUser();
    set({ user: data });
  },
  /* STREAM SERVICE STATE */
  availableStreamServices: [],
  streamService: undefined,
  getStreamService: () => throwIfUndefined(get().streamService, 'streamService state is not defined'),
  setStreamService: (newStreamService: StreamService) => {
    const { streamService } = get();
    if (streamService === undefined || newStreamService.id !== streamService.id) {
      set({ streamService: newStreamService });
      StorageService.setSelectedStream(newStreamService);
    }
  },
  getStreamServiceFromName: (name) => {
    const ss = get().availableStreamServices.find(s => s.name === name);
    if (ss === undefined) throw new Error(`Invalid StreamService name: ${name}`);
    return ss;
  },
  /* HOME SELECTED LIST */
  homeListIndex: 2,
  setHomeListIndex: (num) => set({ homeListIndex: num }),
  /* TOAST, NOTIFICATION AND DOWNLOAD STATE */
  ...createToastState(set, get),
  ...createDownloadState(set, get),
  ...createNotificationState(set, get),
}));

export default useStore;
