class ProxyServer {
  public static readonly URL: string = 'https://animeproxy.fly.dev';

  public static buildUrl(url: string, referer: string | null=null): string {
    const params = [`target=${encodeURIComponent(url)}`];
    if (referer !== null) {
      params.push(`referer=${referer}`);
    }

    const paramString = params.join('&');
    return `${ProxyServer.URL}?${paramString}`;
  }
}

export default ProxyServer;
