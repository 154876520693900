import { useState, useMemo, useCallback, Fragment } from 'react';
import styled, { css } from 'styled-components';
import { HiddenDesktop, HiddenMobile } from './basics';

import { ReactComponent as NavigationNextIcon } from 'assets/icons/navigation-next-icon.svg';
import { ReactComponent as NavigationBackIcon } from 'assets/icons/navigation-back-icon.svg';

export type MenuType = 'LIST' | 'ICON_GRID';

export interface MenuOption {
  leading?: JSX.Element | boolean;
  trailing?: JSX.Element;
  icon?: JSX.Element;
  label: string;
  value?: string;
  pageSelect?: string;
  hideDesktop?: boolean;
  hideMobile?: boolean;
  screenBreakpoint?: number;
  disabled?: boolean;
}

export interface MenuProps {
  type?: MenuType;
  options: MenuOption[] | { [key: string]: MenuOption[] };
  onSelect: (value: string, index: number) => void;
  maxHeight?: string;
  minWidth?: string;
  leadingWidth?: string;
  trailingWidth?: string;
  multiPage?: boolean;
  firstPageName?: string;
  background?: string;
}

function Menu({
  type = 'LIST',
  options = [],
  onSelect,
  maxHeight = 'unset',
  minWidth = 'unset',
  leadingWidth,
  trailingWidth,
  multiPage,
  firstPageName = 'index',
  background,
}: MenuProps) {
  const [page, setPage] = useState(firstPageName);
  const items = useMemo(() => (
    multiPage ? (options as { [key: string]: MenuOption[] })[page] || [] : (options as MenuOption[])
  ), [multiPage, options, page]);
  const addReturnArrow = useMemo(() => page !== firstPageName, [page, firstPageName]);

  const onItemClick = useCallback((value: string | undefined, label: string, index: number, pageSelect: string | undefined) => {
    if (pageSelect)
      setPage(pageSelect);
    else
      onSelect(value === undefined ? label : value, index);
  }, [onSelect, setPage]);

  const GoBack = useCallback(() => (
    <ItemButtom onClick={() => setPage(firstPageName)}>
      <LeftIcon width={leadingWidth}><NavigationBackIcon height='20' /></LeftIcon>
      {page}
    </ItemButtom>
  ), [setPage, leadingWidth, page, firstPageName]);

  const renderListItem = useCallback(({ leading, trailing, label, value, pageSelect, disabled = false }: MenuOption, index: number) => (
    <ItemButtom onClick={() => onItemClick(value, label, index, pageSelect)} disabled={disabled}>
      <LeftIcon width={leadingWidth}>{leading}</LeftIcon>
      {label}
      <RightIcon width={trailingWidth}>{pageSelect ? <NavigationNextIcon /> : trailing}</RightIcon>
    </ItemButtom>
  ), [leadingWidth, trailingWidth, onItemClick]);

  const renderGridItem = useCallback(({ icon, label, value, pageSelect, disabled = false }: MenuOption, index: number) => (
    <ItemButtom onClick={() => onItemClick(value, label, index, pageSelect)} disabled={disabled}>
      <GridItem width='40px'>{icon}</GridItem>
    </ItemButtom>
  ), [onItemClick]);

  const renderFunction = useMemo(() => type === 'LIST' ? renderListItem : renderGridItem, [type, renderListItem, renderGridItem]);

  if (items.length === 0) return null;
  return (
    <MenuContainer className='scrollable' type={type} style={{ minWidth, maxHeight, background }}>
      {addReturnArrow && <GoBack />}
      {items.map((data, i) => {
        const itemCmp = renderFunction(data, i);
        if (data.hideDesktop === true)
          return <HiddenDesktop key={data.label} width={data.screenBreakpoint}>{itemCmp}</HiddenDesktop>;
        else if (data.hideMobile === true)
          return <HiddenMobile key={data.label} width={data.screenBreakpoint}>{itemCmp}</HiddenMobile>;
        else
          return <Fragment key={data.label}>{itemCmp}</Fragment>;
      })}
    </MenuContainer>
  );
}

const MenuContainer = styled.div<{ type: MenuType }>`
  ${p => p.type === 'LIST' && css`
    display: flex;
    flex-direction: column;
    align-items: stretch;
  `}

  ${p => p.type === 'ICON_GRID' && css`
    display: grid;
    column-gap: 10px;
    row-gap: 10px;
    grid-template-columns: repeat(4, 40px);
  `}

  background-color: ${p => p.theme.dark4};
  border-radius: 6px;
  padding: 1rem;
  overflow-y: auto;

  @media screen and (max-width: 425px) {
    padding: 0.5rem;
  }
`;

const ItemButtom = styled.button.attrs({ type: 'button' })`
  display: flex;
  align-items: center;
  border-radius: 6px;
  padding: 0.5rem;
  height: 40px;
  color: white;
  text-align: left;

  &:hover {
    background-color: ${p => p.theme.darkHover};
  }
`;

const LeftIcon = styled.div<{ width?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  ${p => p.width && css<{ width?: string }>`
    width: ${p => p.width};
  `}
`;

const GridItem = styled.div<{ width?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  ${p => p.width && css<{ width?: string }>`
    width: ${p => p.width};
  `}
`;

const RightIcon = styled(LeftIcon)`
  margin-left: auto;
`;

export default Menu;
