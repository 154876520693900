import styled from 'styled-components';

const Select = ({ values, label, name, register }) => (
  <div>
    <Label htmlFor={name}>{label}</Label>
    <SelectTag id={name} name={name} {...register(name)}>
      {values.map((v) => (
        <option value={v} key={v}>{v}</option>
      ))}
    </SelectTag>
  </div>
);

const Label = styled.label`
  margin-bottom: 7px;
  display: block;
  font-weight: bold;
`;

const SelectTag = styled.select`
  border: 1px solid #BFBCBA;
  border-radius: 4px;
  padding: 5px 10px;
  background-color: transparent;
`;

export default Select;
