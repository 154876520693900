import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { UserNotification } from 'api';
import { dateUtils } from 'utils';

export interface EpisodeNotificationUIProps {
  notification: UserNotification;
  onNotification: (notification: UserNotification) => void;
}

function EpisodeNotificationUI({ notification, onNotification }: EpisodeNotificationUIProps) {
  const { notification: { title, body, created_at }, seen } = notification;
  const { ss, remote_id, episode, imgurl } = body;

  return (
    <LinkContainer
      config={{ isSeen: seen }}
      onClick={() => onNotification(notification)}
      to={`/anime/${ss}/${remote_id}${episode ? `?epNum=${episode}` : ''}`}
    >
      <AnimeImage src={imgurl} alt='Anime Cover' />
      <div>
        <p>{title} &nbsp;&nbsp;•&nbsp;&nbsp; <span style={{ fontSize: 13 }}>EP {episode}</span></p>
        <MutedText>
          AnimeWorld &nbsp;&nbsp;•&nbsp;&nbsp; {dateUtils.fmtDate(created_at)}
        </MutedText>
      </div>
    </LinkContainer>
  );
}

const LinkContainer = styled(Link)<{ config: { isSeen: boolean } }>`
  display: flex;
  margin: 5px 0;
  padding: 5px;
  font-size: 14px;
  border-radius: 2px;
  color: ${p => p.theme.textColor};;
  text-align: left;

  &:hover {
    background-color: ${p => p.theme.darkHover};
    color: ${p => p.theme.textColor};
  }

  ${p => p.config.isSeen && css`
    background-color: ${p => p.theme.darkHover};
    opacity: 0.9;
  `}
`;

const AnimeImage = styled.img`
  width: 80px;
  height: 110px;
  object-fit: cover;
  border-radius: 2px;
  margin-right: 10px;
`;

const MutedText = styled.p`
  font-size: 12px;
  font-weight: 600;
  margin-top: 5px;
  color: #bbb;
`;

export default EpisodeNotificationUI