import React from 'react';
import styled from 'styled-components';
import { useStore, toastDataSelector, toastIsVisibleSelector, hideToastSelector } from 'store';
import Alert, { AlertType, AlertData } from 'components/alert';
import { CSSTransition } from 'react-transition-group';
import './transition.css';

export type ToastType = AlertType;
export type ToastData = AlertData;

const ToastComponent = React.memo(() => {
  const nodeRef = React.useRef(null);
  const toastData = useStore(toastDataSelector);
  const isVisible = useStore(toastIsVisibleSelector);
  const hideToast = useStore(hideToastSelector);

  return (
    <Wrapper>
      <CSSTransition nodeRef={nodeRef} in={isVisible} timeout={150} classNames='toast' mountOnEnter={true} unmountOnExit={true}>
        <Conteiner ref={nodeRef} onClick={hideToast}>
          <Alert {...toastData} />
        </Conteiner>
      </CSSTransition>
    </Wrapper>
  );
});

const Wrapper = styled.div`
  position: fixed;
  top: 5vh;
  left: 50%;
  transform: translate(-50%);
  z-index: 10;
`;

const Conteiner = styled.div`
  will-change: transform, opacity;
  transition: transform 150ms ease-in, opacity 150ms;
`;

export default ToastComponent;
