import styled from 'styled-components';

export interface IconActionProps {
  icon: React.FunctionComponent<any>;
  iconProps?: React.SVGProps<SVGSVGElement>;
}

const IconAction = ({ icon: IconComponent, iconProps = {} }: IconActionProps) => (
  <IconWrapper>
    <IconComponent {...{ height: '15', ...iconProps }} />
  </IconWrapper>
);

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 37px;
  width: 40.8px;
  padding: 0;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.05);
  transition: background-color 250ms ease-in-out;

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }

  @media (min-width: 768px) {
    margin: 0 5px;
    height: 39px;
    width: 43px;
  }
`;

export default IconAction;
