import React, { useMemo } from 'react';
import theme from 'theme';
import styled from 'styled-components';
import Modal from 'components/modal';
import { ModalContainer, TextMuted } from 'components/basics'
import { downloadsSelector, useStore, EpisodeDownload } from 'store';

import {ReactComponent as VideoFile} from 'assets/icons/video-file.svg';

export interface DownloadManagerModalProps {
  visible: boolean;
  onOverlayClick: () => void;
}

function DownloadManagerModal({ visible, onOverlayClick }: DownloadManagerModalProps) {
  return (
    <Modal visible={visible} onOverlayClick={onOverlayClick} unmountOnExit={false}>
      <ModalContainer maxWidth='500px' maxHeight='550px'>
        <Modal.FadeIn>
          <DownloadManager />
        </Modal.FadeIn>
      </ModalContainer>
    </Modal>
  );
}

const DownloadManager = React.memo(() => {
  const downloads = useStore(downloadsSelector);
  const { downloading, pending, completed, failed } = useMemo(() => {
    const downloading: EpisodeDownload[] = [];
    const pending: EpisodeDownload[] = [];
    const completed: EpisodeDownload[] = [];
    const failed: EpisodeDownload[] = [];

    downloads.forEach(d => {
      switch (d.state) {
        case 'downloading': downloading.push(d); break;
        case 'pending': pending.push(d); break;
        case 'completed': completed.push(d); break;
        default: failed.push(d); break;
      }
    });

    return { downloading, pending, completed, failed }
  }, [downloads]);

  return (
    <Container>
      <h2>Download Manager</h2>
      
      {downloading.length + pending.length + failed.length + completed.length > 0 ? <>
        {downloading.map(DownloadItem)}
        {pending.map(DownloadItem)}
        {failed.map(DownloadItem)}
        {completed.map(DownloadItem)}
      </> : (
        <TextMuted style={{ textAlign: 'center', marginTop: 35 }}>La lista di download è vuota</TextMuted>
      )}
    </Container>
  );
});

function DownloadItem({ url, progress, size, episodeInfo }: EpisodeDownload) {
  const { animeTitle, episodeTitle } = episodeInfo;
  return (
    <ItemContainer key={url}>
      <VideoFile height='50' color={theme.textMuted} />
      <ItemInfoContainer>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div>
            <TitleText>{animeTitle}</TitleText>
            <SubTitleText>{episodeTitle}</SubTitleText>
          </div>
          <SubTitleText>{!!size ? (size * 1e-6).toFixed() + 'MB' : '?'}</SubTitleText>
        </div>
        <ProgressBar progress={progress} />
      </ItemInfoContainer>
    </ItemContainer>
  );
}

const ProgressBar = ({ progress }: { progress: number }) => (
  <ProgressTrack>
    <ProgressIndicator style={{ width: `${progress * 100}%` }} />
  </ProgressTrack>
);

const Container = styled.div`
  padding: 15px 0;

  h2 {
    font-size: 20;
    text-align: center;
    margin-bottom: 15px;
  }
`;

const ItemContainer = styled.div`
  display: flex;
  padding: 17px 0;
`;

const ItemInfoContainer = styled.div`
  margin-left: 18px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const TitleText = styled.p`
  font-size: 14px;
`;

const SubTitleText = styled.p`
  font-size: 12px;
  font-weight: bold;
`;

const ProgressBase = styled.div`
  height: 5px;
  border-radius: 6px;
`;

const ProgressTrack = styled(ProgressBase)`
  width: 100%;
  background-color: #DFDFDF;
  margin-bottom: 6px;
  margin-top: 3px;
`;

const ProgressIndicator = styled(ProgressBase)`
  background-color: ${p => p.theme.primary};
  transition: width 250ms linear;
`;

export default DownloadManagerModal;
