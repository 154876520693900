import { streamServiceSelector, useStore } from 'store';
import styled from 'styled-components';

function StreamServiceIndicator() {
  const streamService = useStore(streamServiceSelector);

  return <Container>{streamService.name}</Container>;
}

const Container = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  padding: 3px 9px;
  font-size: 13px;
  font-weight: bold;
  opacity: 0.8;
  user-select: none;
  transition: opacity 250ms;
  border-top-right-radius: 4px;
  letter-spacing: 0.5px;
  border-top: 1px solid rgba(255, 255, 255, 0.25);
  border-right: 1px solid rgba(255, 255, 255, 0.25);

  background-color: ${p => p.theme.dark2};

  &:hover {
    opacity: 1;
  }
`;

export default StreamServiceIndicator;
