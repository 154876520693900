class RamCache<ValueType> {
  private data: { [key: string]: ValueType } = {};

  public get(key: string): ValueType | undefined {
    return this.data[key];
  }

  public set(key: string, value: ValueType): void {
    this.data[key] = value;
  }

  public remove(key: string): void {
    delete this.data[key];
  }

  public has(key: string): boolean {
    return this.data.hasOwnProperty(key);
  }

  public pop(key: string): ValueType | undefined {
    const res = this.data[key];
    delete this.data[key];
    return res;
  }

  public clear(): void {
    this.data = {};
  }
}

export default RamCache;
