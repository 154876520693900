import { GlobalStoreState } from './main-store';
import { StoreApi } from 'zustand';
import axios, { CancelTokenSource } from 'axios';
import { downloadVideo, sleep } from 'utils';
import StorageService from 'services/storage.service';

export const CONCURRENT_DOWNLOAD_LIMIT = 4;
const ABORT_MSG = 'Download-Manually-Stopped';
const RETRY_LIMIT = 50;
const FAIL_SLEEP_TIME = 1500;

export type DownloadState = 'downloading' | 'completed' | 'failed' | 'pending';

export interface FileDownload {
  url: string;
  filename: string;
  state: DownloadState;
  progress: number;
  cancelSource?: CancelTokenSource;
  size?: number;
}

export interface EpisodeInfo {
  animeId: string;
  animeTitle: string;
  episodeTitle: string;
  ss: number;
  episodeNumber: number;
  seasonIndex: number;
  episodeIndex: number;
}

export interface EpisodeDownload extends FileDownload {
  episodeInfo: EpisodeInfo;
}

export interface StoreDownloadState {
  downloads: { [key: string]: EpisodeDownload };
  getDownload: (url: string) => EpisodeDownload | undefined;
  getDownloads: () => EpisodeDownload[];
  startDownload: (url: string, episodeInfo: EpisodeInfo) => EpisodeDownload;
  abortDownload: (url: string) => EpisodeDownload;
  restartDownload: (url: string) => EpisodeDownload;
  concurrentDownloadLimit: number;
  setConcurrentDownloadLimit: (newLimit: number) => void;
}

async function safeDownloadVideo(url: string, filename: string, cancelSource: CancelTokenSource, onProgress: (e: any) => void): Promise<void> {
  for (let i=0; i < RETRY_LIMIT; i++) {
    try {
      return await downloadVideo(url, filename, cancelSource, onProgress);
    } catch(e: any) {
      if (e.message === ABORT_MSG) throw e;
      console.warn('Failed', i, 'to download', filename);
      await sleep(FAIL_SLEEP_TIME);
    }
  }
}

function createDownloadState(set: StoreApi<GlobalStoreState>['setState'], get: StoreApi<GlobalStoreState>['getState']): StoreDownloadState {
  const updateDownload = (url: string, newState: Partial<EpisodeDownload>): EpisodeDownload => {
    const { downloads } = get();
    const newEpisodeDownload = { ...downloads[url], ...newState };
    set({ downloads: { ...downloads, [url]: newEpisodeDownload } });
    return newEpisodeDownload;
  };

  const executeVideoDownload = (url: string): EpisodeDownload => {
    const cancelSource = axios.CancelToken.source();
    const episodeDownload = updateDownload(url, { state: 'downloading', cancelSource });
    safeDownloadVideo(url, episodeDownload.filename, cancelSource, (progressEvent: any) => {
      const { loaded, total } = progressEvent;
      updateDownload(url, { progress: loaded / total, size: total });
    }).then(() => {
      updateDownload(url, { state: 'completed' });
    }).catch(() => {
      const { showToast } = get();
      updateDownload(url, { state: 'failed' });
      showToast({ type: 'error', title: 'Download fallito', msg: episodeDownload.filename });
    }).finally(() => {
      // execute pending
      const toStart = get().getDownloads().find(e => e.state === 'pending');
      if (toStart !== undefined) executeVideoDownload(toStart.url);
    });
    return episodeDownload;
  };

  const countActiveDownloads = (): number => {
    return get().getDownloads().reduce((acc, curr) => (
      curr.state === 'downloading' ? acc + 1 : acc
    ), 0);
  };

  return {
    downloads: {},
    getDownload: (url) => get().downloads[url],
    getDownloads: () => Object.values(get().downloads),
    startDownload: (url, episodeInfo) => {
      const { downloads, concurrentDownloadLimit } = get();
      const { animeTitle, episodeTitle, episodeNumber } = episodeInfo;

      const fmtNum = ('' + episodeNumber).padStart(2, '0');  // add 0 at the start if the number is a single digit
      const episodeDownload = {
        url,
        filename: animeTitle === episodeTitle ? animeTitle : `${fmtNum} ${animeTitle} - ${episodeTitle}.mp4`,
        state: 'pending' as DownloadState,
        episodeInfo,
        progress: 0,
      };

      set({ downloads: { ...downloads, [url]: episodeDownload }});
      if (countActiveDownloads() < concurrentDownloadLimit) executeVideoDownload(url);
      return episodeDownload;
    },
    abortDownload: (url) => {
      const episodeDownload = get().getDownload(url);
      // thow error if episode download is not found.
      if (episodeDownload === undefined) throw new Error('Invalid download url');
      // action based on episode download state
      if (episodeDownload.state === 'pending') {
        return updateDownload(episodeDownload.url, { state: 'failed' });
      } else if (episodeDownload.cancelSource !== undefined) {
        episodeDownload.cancelSource.cancel(ABORT_MSG);
      }
      return episodeDownload;
    },
    restartDownload: (url) => {
      const { concurrentDownloadLimit } = get();
      if (countActiveDownloads() < concurrentDownloadLimit) {
        return executeVideoDownload(url);
      } else {
        return updateDownload(url, { state: 'pending' });
      }
    },
    concurrentDownloadLimit: StorageService.getConcurrentDownloadLimit() || CONCURRENT_DOWNLOAD_LIMIT,
    setConcurrentDownloadLimit: (newLimit: number) => {
      const { concurrentDownloadLimit, getDownloads } = get();
      if (newLimit === concurrentDownloadLimit) return;
      set({ concurrentDownloadLimit: newLimit });
      StorageService.setConcurrentDownloadLimit(newLimit);
      // check if it is possible to activate downloads
      let canActiveCount = newLimit - countActiveDownloads();
      if (canActiveCount <= 0) return;
      const ds = getDownloads();
      const len = ds.length;
      for(let i=0; canActiveCount > 0 && i < len; i++) {
        const { url, state } = ds[i];
        if (state === 'pending') {
          executeVideoDownload(url);
          canActiveCount--;
        }
      }
    },
  };
}

export default createDownloadState;
