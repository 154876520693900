import styled, { css } from 'styled-components';

function RadioGroup({ label, name, values, defaultIndex, register, colCount, tabletColCount, mobileColCount }) {
  return (
    <div>
      <FieldName>{label}</FieldName>
      <RadioGrid colCount={colCount} tabletColCount={tabletColCount} mobileColCount={mobileColCount}>
        {values.map((value, index) => {
          const rid = `${name}-${value}`;
          return (
            <RadioContainer key={rid}>
              <Input
                type='radio'
                id={rid}
                name={name}
                value={value}
                defaultChecked={defaultIndex === index}
                {...register(name)}
              />
              <Label htmlFor={rid}>{value}</Label>
            </RadioContainer>
          );
        })}
      </RadioGrid>
    </div>
  );
}

const FieldName = styled.h3`
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 7px;
`;

const Input = styled.input`
  margin: 0 5px 0 0;
  padding: 0;
  cursor: pointer;
`;

const Label = styled.label`
  margin-top: 2px;
  padding: 0 2px;
  font-size: 13px;
  cursor: pointer;
`;

const RadioContainer = styled.div`
  display: flex;
  align-items: center;
`;

const RadioGrid = styled.div`
  display: grid;
  row-gap: 10px;
  column-gap: 15px;
  grid-template-columns: repeat(${p => p.colCount}, 1fr);

  ${p => p.tabletColCount && css`
    @media (max-width: 768px) {
      grid-template-columns: repeat(${p => p.tabletColCount}, 1fr);
    }
  `}

  ${p => p.mobileColCount && css`
    @media (max-width: 425px) {
      grid-template-columns: repeat(${p => p.mobileColCount}, 1fr);
    }
  `}
`;

export default RadioGroup;
