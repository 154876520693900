import { useMediaLayout } from 'use-media';
import DesktopNotificationMenu from './NotificationMenu.desktop';
import MobileNotificationMenu from './NotificationMenu.mobile';

function NotificationMenu() {
  const isMobile = useMediaLayout({ maxWidth: 460 });

  return isMobile ? <MobileNotificationMenu /> : <DesktopNotificationMenu />;
}

export default NotificationMenu;
