import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, Outlet } from 'react-router-dom';
// Services import
import { useStore, loggedInSelector, appLoadingSelector } from './store';
import theme, { GlobalStyle } from './theme';
// contexs import
import { ThemeProvider } from 'styled-components';
// Layout import
import Header from './layout/header/Header';
import LoadingBar from './components/loaders/LoadingBar';
import Toast from './layout/toast/Toast';
import StreamServiceIndicator from 'components/StreamServiceIndicator';
// Pages import
const LoginPage = React.lazy(() => import('./pages/login/Login')); 
const HomePage = React.lazy(() => import('./pages/home/Home')); 
const AnimePage = React.lazy(() => import('./pages/anime/Anime')); 
const SearchPage = React.lazy(() => import('./pages/search/Search')); 
const GenrePage = React.lazy(() => import('./pages/genre/Genre'));
const LastEpisodesPage = React.lazy(() => import('./pages/last-episodes/LastEpisodes'));

// start app
useStore.getState().startApp();

function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Router>
        <AppBody />
      </Router>
      <Toast />
    </ThemeProvider>
  );
}

function AppBody() {
  const appLoading = useStore(appLoadingSelector);
  const loggedIn = useStore(loggedInSelector);

  if (appLoading) return <LoadingBar height={4} />;
  return (
    <>
      <Header preview={!loggedIn} />
      <main id="main">
        <Suspense fallback={<LoadingBar />}>
          <Routes>
            <Route element={<AuthRoute logged={loggedIn} />}>
              <Route path='/auth' element={<LoginPage />} />
            </Route>
            
            <Route element={<PrivateRoute logged={loggedIn} />}>
              <Route path='/' element={<HomePage />} />
              <Route path='/anime/:ss/:id' element={<AnimePage />} />
              <Route path='/search/anime/:ss' element={<SearchPage />} />
              <Route path='/genre/:ss/:genre' element={<GenrePage />} />
              <Route path='/:ss/last-ep' element={<LastEpisodesPage />} /> 
            </Route>
          </Routes>
        </Suspense>
      </main>

      {loggedIn && <StreamServiceIndicator />}
    </>
  );
};

const AuthRoute = ({ logged }: { logged: boolean }) => !logged ? <Outlet /> : <Navigate to='/' />;
const PrivateRoute = ({ logged }: { logged: boolean }) => logged ? <Outlet /> : <Navigate to='/auth' />;

export default App;
