import styled from 'styled-components';

export type AlertType = 'default' | 'success' | 'warning' | 'error';

export interface AlertData {
  type?: AlertType;
  title?: string;
  msg: string;
}

const Alert = ({ type, title, msg }: AlertData) => (
  <StyledAlert type={type || 'default'}>
    {title && <h5>{title}</h5>}
    <p>{msg}</p>
  </StyledAlert>
);

const StyledAlert = styled.div<{ type: AlertType }>`
  background-color: ${p => p.theme.alert[p.type].background};
  border: 1px solid ${p => p.theme.alert[p.type].border};
  padding: 10px 20px;
  border-radius: 5px;

  & h5 {
    margin-bottom: 5px;
    color: ${p => p.theme.alert[p.type].text};
  }

  & p {
    font-size: 14px;
    color: ${p => p.theme.alert[p.type].text};
  }
`;

export default Alert;
