import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useStore, streamServiceSelector } from 'store';
import { TextInput, ToggleSwitch, SubmitButton, RadioGroup, Select } from 'components/form-fields';
import { EmptySpace, ModalContainer } from 'components/basics';
import Modal from 'components/modal';
import { GENRES, ORDER_BY } from './config';


export interface AdvancedSearchModalProps {
  visible: boolean;
  onOverlayClick: () => void;
  requestClose: () => void;
}

function AdvancedSearchModal({ visible, onOverlayClick, requestClose }: AdvancedSearchModalProps) {
  return (
    <Modal visible={visible} onOverlayClick={onOverlayClick} unmountOnExit={false}>
      <ModalContainer maxWidth='450px'>
        <AdvancedSearch onCompleted={requestClose} />
      </ModalContainer>
    </Modal>
  );
}

interface FormData {
  name: string;
  genre: string;
  isDesc: boolean;
  orderBy: keyof typeof ORDER_BY;
}

function AdvancedSearch({ onCompleted=()=>{} }) {
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm<FormData>();
  const streamService = useStore(streamServiceSelector);
  const ssKey = streamService.name.toLowerCase() === 'hentaiworld' ? 'HW' : 'default';

  const onSubmit = (data: FormData) => {
    const genre = GENRES[ssKey][data.genre];
    const q = new URLSearchParams();
    if (data.name !== '') q.append('title', data.name);
    if (genre !== null) q.append('genres', genre);
    q.append('order_type', data.isDesc ? 'DESC' : 'ASC');
    q.append('orderby', ORDER_BY[data.orderBy]);
    navigate({
      pathname: `/search/anime/${streamService.id}`,
      search: '?' + q.toString(),
    });
    onCompleted();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h2 style={{ textAlign: 'center' }}>Ricerca Avanzata</h2>

      <TextInput label='Nome' name='name' register={register} placeholder='Es. Mushishi' error={undefined} defaultValue={undefined} />
      <EmptySpace height='25px' />
      <Select label='Genere' values={Object.keys(GENRES[ssKey])} name='genre' register={register} />
      <EmptySpace height='25px' />
      <ToggleSwitch label='Ordine descrescente' name='isDesc' register={register} />
      <EmptySpace height='25px' />
      <RadioGroup
        label='Ordina per'
        name='orderBy'
        values={Object.keys(ORDER_BY)}
        defaultIndex={2}
        colCount='4'
        mobileColCount='2'
        register={register}
        tabletColCount={undefined}
      />

      <SubmitButton>Cerca</SubmitButton>
    </form>
  );
}

export default AdvancedSearchModal;
