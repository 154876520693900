import styled from 'styled-components';
import { ReactComponent as SearchSVG } from 'assets/icons/search-icon.svg';


export const DesktopForm = styled.form`
  position: relative;
  width: 30%;
  @media (max-width: 1024px) {
    width: 50%;
  }
`;

export const MobileForm = styled.form`
  margin: 0 10px 0 auto;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 5px;
  overflow: hidden;

  &:focus-within {
    box-shadow: 0 0 0 3px ${p => p.theme.primary};
  }
`;

export const TextInput = styled.input`
  padding: 10px 1em;
  width: 100%;
  border: none;
  font-size: 14px;
`;

export const SubmitButton = styled.button.attrs({ type: 'submit' })`
  padding: 0 0.7em;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: none;
  transition: background-color 250ms ease-in;
  border-left: 1px solid #e6e4e3;

  &:hover {
    background-color: rgba(255, 255, 255, 0.8);
  }
`;

export const MobileInputContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${p => p.theme.dark1};
`;

export const MobileInputWrapper = styled(InputWrapper)`
  flex: 1;
  max-width: 350px;
  margin-left: 20px;
`;

export const SearchIcon = styled(SearchSVG)`
  display: block;
  & #main-path {
    fill: ${p => p.color || 'black'};
  }
`;

export const CloseButton = styled.button`
  display: flex;
  align-items: center;
  padding: 0 10px;
  margin: 0 10px;
`;
