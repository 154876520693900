import ProxyServer from "services/proxy.service";
import CachedApi from "./cached-api";
import { AnimeInfo, FullUserAnime, PaginationResult, SearchParams, UserNotification, VideoUrls, WatchState } from "./interfaces";

class CorsProxyApi extends CachedApi {
  private static readonly REQUIRE_PROXY: { [key: string]: string } = {
    'cdn.hentaiworld.me' : 'https://www.hentaiworld.me/',
    'cdn.hentaiworld.eu' : 'https://www.hentaiworld.me/',
    'server.hentaiworld.me': 'https://www.hentaiworld.me/',
  };

  public async getNotifications(): Promise<UserNotification[]> {
    const data = await super.getNotifications();
    data.forEach(i => {
      i.notification.body.imgurl = this.fixUrl(i.notification.body.imgurl);
    })
    return data;
  }

  public async getAnime(ss: number, id: string): Promise<FullUserAnime> {
    const data = await super.getAnime(ss, id);
    data.anime.imgurl = this.fixUrl(data.anime.imgurl);
    return data;
  }

  public async getEpisodeVideoUrls(ss: number, epUrl: string): Promise<VideoUrls> {
    const data = await super.getEpisodeVideoUrls(ss, epUrl);
    data.available = data.available.map(this.fixUrl) as string[];
    return data;
  }

  public async autocomplete(ss: number, title: string): Promise<AnimeInfo[]> {
    const data = await super.autocomplete(ss, title);
    data.forEach(i => {
      i.imgurl = this.fixUrl(i.imgurl);
    })
    return data;
  }

  public async search(ss: number, params: SearchParams, page: number = 1): Promise<PaginationResult<AnimeInfo>> {
    const data = await super.search(ss, params, page);
    data.data.forEach(i => {
      i.imgurl = this.fixUrl(i.imgurl);
    });
    return data;
  }

  public async getGenreAnime(ss: number, genre: string, page: number): Promise<PaginationResult<AnimeInfo>> {
    const data = await super.getGenreAnime(ss, genre, page);
    data.data.forEach(i => {
      i.imgurl = this.fixUrl(i.imgurl);
    });
    return data;
  }

  public async getLastEpisodes(ss: number): Promise<(AnimeInfo & { episode: number })[]> {
    const data = await super.getLastEpisodes(ss);
    data.forEach(i => {
      i.imgurl = this.fixUrl(i.imgurl);
    });
    return data;
  }

  public async followed(ss: number): Promise<FullUserAnime[]> {
    const data = await super.followed(ss);
    data.forEach(i => {
      i.anime.imgurl = this.fixUrl(i.anime.imgurl);
    });
    return data;
  }

  public async lastRequested(ss: number): Promise<FullUserAnime[]> {
    const data = await super.lastRequested(ss);
    data.forEach(i => {
      i.anime.imgurl = this.fixUrl(i.anime.imgurl);
    });
    return data;
  }

  public async userAnimeList(ss: number, watchState: WatchState): Promise<FullUserAnime[]> {
    const data = await super.userAnimeList(ss, watchState);
    data.forEach(i => {
      i.anime.imgurl = this.fixUrl(i.anime.imgurl);
    });
    return data;
  }

  private fixUrl(url: string | undefined): string | undefined {
    if (typeof url !== 'string') return undefined;
    const urlObj = new URL(url);
    const ref = CorsProxyApi.REQUIRE_PROXY[urlObj.hostname];
    if (ref !== undefined)
      return ProxyServer.buildUrl(url, ref);
    return url;
  }
}

export default CorsProxyApi;
