import styled, { keyframes } from 'styled-components';

const CircularLoader = ({ size=50, strokeWidth=6, color, background='rgba(255,255,255,0.05)' }) => (
  <Loader {...{ size, strokeWidth, color, background }} />
);

const spin = keyframes`
  0% {
    transform: rotate(0deg);
    }
  100% {
    transform: rotate(360deg);
  }
`;

const Loader = styled.div`
  width: ${p => p.size}px;
  height: ${p => p.size}px;
  border: ${p => p.strokeWidth}px solid ${p => p.background};
  border-top: ${p => p.strokeWidth}px solid ${p => p.color || p.theme.primary};
  border-radius: 50%;
  animation: ${spin} 1s ease-in-out infinite;
`;

export default CircularLoader;
