export interface StreamService {
  id: number;
  name: string;
}

class StorageService {
  // token methods
  public static getToken(): string | null {
    return localStorage.getItem('token');
  }

  public static setToken(newToken: string): void {
    localStorage.setItem('token', newToken);
  }

  public static removeToken(): void {
    localStorage.removeItem('token');
  }

  // selected stream methods
  public static getSelectedStream(): StreamService | null {
    const data = localStorage.getItem('selected-stream');
    return data && JSON.parse(data);
  }

  public static setSelectedStream(newStream: StreamService): void {
    localStorage.setItem('selected-stream', JSON.stringify(newStream));
  }

  // concurrent download limit methods
  public static getConcurrentDownloadLimit(): number | null {
    const data = localStorage.getItem('cdl');
    return data === null ? null : +data;
  }

  public static setConcurrentDownloadLimit(newCdl: number): void {
    localStorage.setItem('cdl', ''+newCdl);
  }

  // volume methods
  public static getVolume(): number | null {
    const data = localStorage.getItem('video:volume');
    if (data === null) return null;
    
    const volume = +data;
    return isNaN(volume) ? null : volume;
  }

  public static setVolume(newVolume: number): void {
    localStorage.setItem('video:volume', ''+newVolume);
  }
}

export default StorageService;
