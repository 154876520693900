import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const HeaderWrapper = styled.header`
  position: relative;
  display: flex;
  height: 65px;
  padding: 0 20px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background-color: ${p => p.theme.dark1};
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.22);

  ${p => p.isTransparent && css`
    background-color: transparent;
    box-shadow: none;
  `}

  @media (min-width: 768px) {
    padding: 0 3.5vw;
  }
`;

export const AppName = styled(Link)`
  margin-bottom: -2px;
  font-size: 1.5em;
  font-weight: normal;
  color: white;
  text-decoration-line: none;
  transform: translateY(-2px);

  &:hover {
    color: white;
  }

  @media (max-width: 768px) {
    font-size: 1.3em;
  }
`;

export const SettingsInputContainer = styled.div`
  display: flex;
  align-items: center;
`;
