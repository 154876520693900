import { UserAnime } from 'api';
import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { animeLastNotificationMapSelector, useStore } from 'store';

export function useStreamServiceParam(): number {
  const { ss } = useParams<{ ss: string }>();
  
  return  useMemo(() => {
    if (ss === undefined) throw new Error('Unable to extract stream service from url params');
    return parseInt(ss);
  }, [ss]);
}

export function useQuery<ArgsType extends any[], T>(query: (...args: ArgsType) => Promise<T>) {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<any | null>(null);

  const fetchData = useCallback(async (...args: ArgsType) => {
    setLoading(true);
    try {
      const data = await query(...args);
      setData(data);
      setLoading(false);
    } catch(e: any) {
      setError(e);
      setLoading(false);
      throw e;
    }
  }, [query]);

  return { data, setData, loading, fetchData, error };
}

export function useAnimeHasNewEpisodes(ua: UserAnime): boolean {
  const animeLastNotificationMap = useStore(animeLastNotificationMapSelector);
  return useMemo(() => {
    const { anime } = ua;
    const not = (animeLastNotificationMap[anime.ss] || {})[anime.remote_id];
    const lastSeenEp = ua.last_seen_episode + 1;
    return !!not && !not.seen && (not.notification.body.episode || 1) > lastSeenEp;
  }, [ua, animeLastNotificationMap]);
}
