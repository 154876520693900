import Axios, { AxiosRequestConfig } from 'axios';
import {
  User,
  SessionInfo,
  AnimeInfo,
  SearchParams,
  PaginationResult,
  VideoUrls,
  UserAnime,
  UserAnimeEditable,
  UserNotification,
  FullUserAnime,
  WatchState,
} from './interfaces';

abstract class BaseApi {
  static readonly BASE_URL = process.env.REACT_APP_BASE_URL || 'https://anime-backend.fly.dev';

  public readonly baseURL = BaseApi.BASE_URL;
  private readonly axios = Axios.create({
    baseURL: new URL('', BaseApi.BASE_URL).toString(),
    headers: {'Accept': 'application/json'},
  });

  constructor(token?: string) {
    if (token)
      this.axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }

  public getToken(): string | undefined {
    const auth = this.axios.defaults.headers.common['Authorization']
    if (typeof auth === 'string') return auth.slice('Bearer '.length);
    return undefined;
  }

  public addToken(token: string) {
    this.axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }

  public removeToken() {
    delete this.axios.defaults.headers.common['Authorization'];
  }

  public async get(url: string, params?: AxiosRequestConfig["params"]): Promise<any> {
    const { data } = await this.axios.get(url, { params }); 
    return data;
  }

  public async post(url: string, data?: any): Promise<any> {
    const { data: resData } = await this.axios.post(url, data);
    return resData;
  }

  public async patch(url: string, data: any): Promise<any> {
    const { data: resData } = await this.axios.patch(url, data);
    return resData;
  }

  /* Utils Methods */
  public isErrorCode(e: any, code: number): boolean {
    return e?.response?.status === code;
  }
}

class Api extends BaseApi {
  /* Authentication Requests */
  public async login(username: string, password: string): Promise<SessionInfo & { token: string }> {
    const data = await this.post('/auth/login', { username, password });
    super.addToken(data.token);
    return data;
  }

  public async logout(): Promise<void> {
    super.removeToken();
  }

  /* Generic Requests */
  public async getServerInfo(): Promise<SessionInfo> {
    return this.get('/auth/info');
  }

  public async getUser(): Promise<User> {
    return this.get('/auth/me');
  }

  public async getNotifications(): Promise<UserNotification[]> {
    const data = await this.get('/notification/all');
    data.forEach((n: UserNotification) => n.notification.created_at = new Date(n.notification.created_at));
    return data;
  }

  public async markNotification(id: number): Promise<{ notification: number, seen: boolean }> {
    return this.patch('/notification', { notification: id, seen: true });
  }

  /* Anime Requests */
  public async getAnime(ss: number, id: string): Promise<FullUserAnime> {
    return this.get(`/anime/${ss}/get/${id}`);
  }

  public async getEpisodeVideoUrls(ss: number, epUrl: string): Promise<VideoUrls> {
    return this.get(`/anime/${ss}/episode_video_urls`, { episode_url: epUrl });
  }

  public async autocomplete(ss: number, title: string): Promise<AnimeInfo[]> {
    return this.get(`/anime/${ss}/autocomplete`, { title });
  }

  public async search(ss: number, params: SearchParams, page: number = 1): Promise<PaginationResult<AnimeInfo>> {
    return this.get(`/anime/${ss}/search`, { ...params, page });
  }

  public async getGenreAnime(ss: number, genre: string, page: number): Promise<PaginationResult<AnimeInfo>> {
    return this.get(`/anime/${ss}/genre`, { name: genre, page });
  }

  public async getLastEpisodes(ss: number): Promise<(AnimeInfo & { episode: number })[]> {
    return this.get(`/anime/${ss}/last_episodes`);
  }

  /* User Anime Requests */
  public async userAnimeList(ss: number, watchState: WatchState): Promise<FullUserAnime[]> {
    return this.get(`/user_anime/${ss}/all`, { watch_state: watchState });
  }
  
  public async followed(ss: number): Promise<FullUserAnime[]> {
    return this.get(`/user_anime/${ss}/all`, { followed: true });
  }

  public async lastRequested(ss: number): Promise<FullUserAnime[]> {
    return this.get(`/user_anime/${ss}/all`, { limit: 7 });
  }

  public async updateAnime(jsonForm: UserAnimeEditable): Promise<UserAnime> {
    return this.patch(`/user_anime`, jsonForm);
  }

  /* External Service Requests */
  public async getAnilistBanner(url: string): Promise<string> {
    return this.get('/external_services/anilist/banner', { url });
  }
}

export default Api;
