import styled, { keyframes } from 'styled-components';

export interface LoadingBarProps {
  height?: number;
  indicator?: string;
}

const LoadingAnimation = keyframes`
  from {
    left: -30%;
    right: 100%;
  }

  to {
    left: 100%;
    right: -15%;
  }
`;

const LoadingBar = styled.div<LoadingBarProps>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: ${p => p.height || 4}px;
  background-color: ${p => p.indicator || 'transparent'};
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    animation: ${LoadingAnimation} 1.25s linear infinite;
    background-color: ${p => p.indicator || p.theme.primary};
  }
`;

export const WrappedLoadingBar = (props: LoadingBarProps) => (
  <div style={{ position: 'relative' }}><LoadingBar {...props} /></div>
);

export default LoadingBar;
