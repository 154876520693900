import { useEffect, useCallback, useRef } from 'react';
import styled from 'styled-components';
import Dropdown from '../dropdown';
import {ReactComponent as DropDownArrow} from 'assets/icons/arrow-drop-down.svg';

export interface ButtonDropdownProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  value?: string;
  disabled?: boolean;
  renderTrigger?: (data: { value?: string }) => JSX.Element;
  position?: 'top' | 'bot';
  containerTransform?: string;
  children: React.ReactNode;
}

function ButtonDropdown({
  visible,
  setVisible,
  value,
  disabled=false,
  renderTrigger=createTriggerRender(),
  position,
  containerTransform,
  children,
}: ButtonDropdownProps) {
  const containerRef = useRef<HTMLDivElement>(null);

  const toggleFocused = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setVisible(v => !v);
  }, [setVisible]);

  useEffect(() => {
    const onDocumentClick = (e: any) => {
      if (!containerRef.current?.contains(e.target)) setVisible(false);
    };
    
    if (visible === true) document.addEventListener('mousedown', onDocumentClick);
    return () => document.removeEventListener('mousedown', onDocumentClick);
  }, [visible, setVisible]);

  useEffect(() => {
    if (disabled === true) setVisible(false);
  }, [disabled, setVisible]);

  return (
    <div ref={containerRef} style={{ position: 'relative' }}>
      <button onClick={toggleFocused} onDoubleClick={e => e.stopPropagation()} disabled={disabled}>
        {renderTrigger({ value })}
      </button>
      
      <Dropdown position={position} containerTransform={containerTransform} visible={visible}>
        {children}
      </Dropdown>
    </div>
  );
}

export const createTriggerRender = (options = { width: undefined }) => {
  return ({ value }: { value?: string }) => (
    <TriggerContainer style={{ width: options.width }}>
      <p>{value}</p>
      <div>
        <DropDownArrow height='2em' width={undefined} />
      </div>
    </TriggerContainer>
  );
}

const TriggerContainer = styled.div`
  color: white;
  display: flex;
  align-items: center;
  background: rgba(91, 101, 113, 0.44);
  padding: 0.3rem 0 0.3rem 0.9rem;
  font-size: 14px;
  border-radius: 4px;

  & p {
    flex: 1;
  }

  & div {
    padding: 0 0.5rem;
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  &:hover {
    background: rgba(130, 140, 153, 0.44);
  }
`;

export default ButtonDropdown;
