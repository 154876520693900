import styled from 'styled-components';
import IconAction from '../IconAction';
import { CircularLoader } from '../loaders';

import {ReactComponent as BellIcon} from 'assets/icons/bell-icon.svg';

export const renderTrigger = ({ value }: { value?: string }) => {
  return (
    <div style={{ position: 'relative' }}>
      <IconAction icon={BellIcon} />
      {value !== undefined && !isNaN(+value) && +value > 0 && <Bubble>
        <p>{value}</p>
      </Bubble>}
    </div>
  );
}

export const Loader = () => (
  <div style={{ margin: '25px auto' }}>
    <CircularLoader color='white' />
  </div>
);

const Bubble = styled.div`
  position: absolute;
  top: -2px;
  right: -2px;
  background-color: #e67700;
  border-radius: 9px;
  width: 18px;
  height: 18px;
  pointer-events: none;

  p {
    margin-top: 1px;
    color: white;
    font-size: 12px;
  }
`;
