import styled from 'styled-components';

const SubmitButton = styled.button.attrs({ type: 'submit' })`
  margin: 30px 0 15px 0;
  height: 36px;
  display: block;
  width: 100%;
  font-size: medium;
  font-weight: bold;
  color: white;
  background-color: ${p => p.theme.primaryDark};
  border: none;
  border-radius: 4px;
  transition: background-color 250ms;

  &:hover {
    background-color: ${p => p.theme.primary};
  }

  &:disabled {
    opacity: 1;
    background-color: ${p => p.theme.primary};
    cursor: not-allowed;
  }
`;

export default SubmitButton;
