import { PropsWithChildren, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import styled, { keyframes } from 'styled-components';
import './style.css';

export interface ModalProps {
  visible: boolean;
  onOverlayClick: React.MouseEventHandler<HTMLDivElement>;
  mountOnEnter?: boolean;
  unmountOnExit?: boolean;
}

const Modal= ({ visible, children, onOverlayClick, mountOnEnter=true, unmountOnExit=true }: PropsWithChildren<ModalProps>) => {
  const nodeRef = useRef(null);

  return (
    <CSSTransition nodeRef={nodeRef} in={visible} timeout={200} classNames='modal' {...{ mountOnEnter, unmountOnExit }}>
      <div ref={nodeRef} className='modal-screen-wrapper'>
        <Overlay onClick={onOverlayClick} />
        <Content className='modal-content'>
          {children}
        </Content>
      </div>
    </CSSTransition>
  );
}

const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
`;

const Content = styled.div`
  z-index: 4;
  transform: translateY(-40px);
  transition: transform 200ms ease-out;
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const FadeInDiv = styled.div<{ delay?: number }>`
  opacity: 0;
  transform: translateY(-10px);
  animation: ${fadeIn} 350ms ease-in;
  animation-delay: ${p => p.delay || 200}ms;
  animation-fill-mode: forwards;
`;

Modal.FadeIn = FadeInDiv;
export default Modal;
