import styled, { keyframes } from 'styled-components';

const pulseKeyframes = keyframes`
  50% {
    background-color: #292d338f;
  }
`;

const Box = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 6px;
  background-color: #292d33;
  animation: ${pulseKeyframes} 1s infinite;
`;

export const BoxPulseLoader = Box;
export default BoxPulseLoader;
