import axios, { CancelTokenSource } from 'axios';
import ProxyService from 'services/proxy.service';
import { saveAs } from 'file-saver';
import { Season } from 'api';

export const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export const previousEpisode = (seasons: Season[], seasonIndex: number, episodeIndex: number) => {
  if (seasonIndex < 0) return null;
  const prevSIndex = seasonIndex-1;
  const prevEpIndex = episodeIndex-1;

  if (prevEpIndex >= 0) {
    return {
      seasonIndex: seasonIndex,
      episodeIndex: prevEpIndex
    };
  } else if (prevSIndex >= 0) {
    // calc previous season last episode index
    const index = seasons[prevSIndex].episodes.length -1;
    return {
      seasonIndex: prevSIndex,
      episodeIndex: index
    };
  }
  return null;
};

export const nextEpisode = (seasons: Season[], seasonIndex: number, episodeIndex: number) => {
  if (seasonIndex < 0) return null;
  const { episodes } = seasons[seasonIndex];
  const nextSIndex = seasonIndex+1;
  const nextEpIndex = episodeIndex+1;

  if (nextEpIndex < episodes.length) {
    return {
      seasonIndex: seasonIndex,
      episodeIndex: nextEpIndex
    };
  } else if (nextSIndex < seasons.length) {
    return {
      seasonIndex: nextSIndex,
      episodeIndex: 0
    };
  }
  return null;
};

export const calculateEpidoseNumber = (seasons: Season[], seasonIndex: number, episodeIndex: number) => {
  let result = 1 + episodeIndex;
  for (let i=0; i<seasonIndex; i++)
    result += seasons[i].episodes.length;
  return result;
};

export const convertEpisodeNumber = (seasons: Season[], epNum: number) => {
  if (epNum < 0)
    throw new Error('Invalid episode number');
  let seasonIndex = 0;
  epNum--;
  while (epNum >= seasons[seasonIndex].episodes.length) {
    epNum -= seasons[seasonIndex].episodes.length;
    seasonIndex++;
    if (seasonIndex >= seasons.length) throw new Error('Invalid episode number');
  }
  return [seasonIndex, epNum];
};

export const downloadVideo = async (url: string, filename: string, cancelSource: CancelTokenSource, onProgress?: (progressEvent: any) => void) => {
  const res = await axios.request({
    url: ProxyService.buildUrl(url),
    baseURL: undefined,
    responseType: 'blob',
    cancelToken: cancelSource.token,
    headers: {
      'Accept': 'video/mp4',
    },
    onDownloadProgress: onProgress,
  });
  saveAs(res.data, filename);
};
