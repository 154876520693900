import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { markNotificationsSelector, notificationLoadingSelector, notificationsSelector, useStore } from 'store';
import { UserNotification } from 'api';
import ButtonDropdown from '../button-dropdown/ButtonDropdown';
import EpisodeNotificationUI from './EpisodeNotificationUI';
import { renderTrigger, Loader } from './common';

const DesktopNotificationMenu = React.memo(() => {
  const [visible, setVisible] = useState(false);
  const notifications = useStore(notificationsSelector);
  const loading = useStore(notificationLoadingSelector);
  const markNotification = useStore(markNotificationsSelector);
  const unseenCount = useMemo(() => {
    return notifications.reduce((acc, element) => acc + (element.seen ? 0 : 1), 0);
  }, [notifications]);

  const onNotification = useCallback((notification: UserNotification) => {
    if (!notification.seen) markNotification(notification);
    setVisible(false);
  }, [markNotification]);

  return (
    <ButtonDropdown
      visible={visible}
      setVisible={setVisible}
      value={'' + unseenCount}
      renderTrigger={renderTrigger}
      containerTransform='translateX(5vw)'
    >
      <MenuContainer className='scrollable'>
        <h2>Notifiche</h2>
        {loading ? <Loader /> : (
          <>
            {notifications.map(n => (
              <EpisodeNotificationUI key={n.notification.id} notification={n} onNotification={onNotification} />
            ))}
            {notifications.length === 0 && <MutedText>Non hai nessuna notifica</MutedText>}
          </>
        )}
      </MenuContainer>
    </ButtonDropdown>
  );
});

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: ${p => p.theme.dark4};
  border-radius: 4px;
  padding: 0.7rem 1rem;
  width: 360px;
  min-height: 250px;
  max-height: 512px;

  h2 {
    margin: 10px 0;
  }
`;

const MutedText = styled.p`
  color: #ddd;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  margin-top: 45px;
`;

export default DesktopNotificationMenu;
