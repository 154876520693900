import Api from 'api';
import { hooksUtils } from 'utils';

const query: typeof Api.lastRequested = (...args) => Api.lastRequested(...args);

function useLastSeen() {
  return hooksUtils.useQuery(query);
}

export default useLastSeen;
