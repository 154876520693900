export type WatchState = 0 | 1 | 2 | 3;

export interface User {
  id: number;
  username: string;
  email: string;
  is_superuser: boolean;
  created_at: string;
  updated_at: string;
}

export interface Genre {
  id: string;
  label: string;
}

export interface StreamService {
  id: number;
  name: string;
}

export interface SessionInfo {
  user: User;
  services: StreamService[];
}

export interface AnimePreview {
  remote_id: string;
  ss: number;
  title: string;
  imgurl?: string;
}

export interface AnimeInfo {
  remote_id: string;
  title: string;
  imgurl?: string;
  episode?: number;
}

export interface Anime extends AnimeInfo {
  id: number;
  ss: number;
  plot: string;
  genres: Genre[];
  ep_count: number;
  global_rate: number;
  date: string;
  completed?: boolean;
  seasons: Season[];
  related: AnimeInfo[];
  similar: AnimeInfo[];
  external_links: ExternalLink[];
}

export interface UserAnime {
  id: number;
  anime: AnimePreview;
  favorite: boolean;
  watch_state: WatchState;
  last_seen_season: number;
  last_seen_episode: number;
  last_seen_date: string;
}

export interface FullUserAnime extends UserAnime {
  anime: Anime;
}

export type UserAnimeEditable = { id: number } & Partial<Pick<
  UserAnime,
  'favorite' |
  'watch_state' |
  'last_seen_season' |
  'last_seen_episode'
>>;

export interface Episode {
  title: string;
  url: string;
}

export interface Season {
  name: string;
  episodes: Episode[];
}

export interface PaginationResult<T> {
  page_count: number;
  current_page: number;
  data: T[];
}

export interface VideoRedirect {
  host: string;
  url: string;
}

export interface VideoUrls {
  available: string[];
  redirect: VideoRedirect[];
}

export type OrderBy = 'name' | 'rate' | 'recent' | 'date';
export type OrderType = 'ASC' | 'DESC';
export interface SearchParams {
  title?: string;
  orderby?: OrderBy;
  order_type?: OrderType;
  genres?: string[];
  page?: number;
}

export enum NotificationType { EPISODE = 0 };
export interface Notification {
  id: number;
  title: string;
  body: AnimePreview & { episode?: number };
  type: NotificationType;
  created_at: Date;
}

export interface UserNotification {
  notification: Notification;
  seen: boolean;
}

export interface ExternalLink {
  target: string;
  url: string;
  imgurl: string;
}

export interface VideoData {
  title: string;
  subTitle: string;
  seasonIndex: number;
  episodeIndex: number;
  redirect: boolean;
  url?: string;
  host?: string;
}
