import { StoreApi } from 'zustand';
import { ToastData } from '../layout/toast/Toast';
import { GlobalStoreState } from './main-store';

export interface StoreToastState {
  toastData: ToastData;
  toastIsVisible: boolean;
  showToast: (newData: ToastData, timeout?: number) => void;
  hideToast: () => void;
}

function createToastState(set: StoreApi<GlobalStoreState>['setState'], get: StoreApi<GlobalStoreState>['getState']): StoreToastState {
  return {
    toastData: { msg: '' },
    toastIsVisible: false,
    showToast: (newData: ToastData, timeout?: number) => {
      const { toastIsVisible, showToast, hideToast } = get();
      if (toastIsVisible) {
        setTimeout(() => showToast(newData, timeout), 150);
      } else {
        set({ toastData: newData, toastIsVisible: true });
        setTimeout(hideToast, timeout || 1500);
      }
    },
    hideToast: () => set({ toastIsVisible: false }),
  };
}

export default createToastState;
