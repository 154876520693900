import { useState, useCallback, useEffect } from 'react';

function useModal() {
  const [visible, setVisible] = useState(false);

  const showModal = useCallback(() => {
    setVisible(true);
  }, [setVisible]);

  const hideModal = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  useEffect(() => {
    if (visible) document.body.classList.add('disable-scroll');
    return () => {
      document.body.classList.remove('disable-scroll');
    }
  }, [visible]);

  return { visible, showModal, hideModal };
}

export default useModal;
