import styled from 'styled-components';

export interface CheckboxProps {
  name: string;
  label: string;
  onChange: (newValue: boolean) => void;
}

function Checkbox({ name, label, onChange }: CheckboxProps) {
  return (
    <Container>
      <Label htmlFor={name}>{label}</Label>
      <Input id={name} name={name} type='checkbox' onChange={e => onChange(e.target.checked)} />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
`;

const Label = styled.label`
  display: block;
  font-size: 14px;
`;

const Input = styled.input`
  display: block;
  margin: 1px 0 0 12px;
  width: 15px;
  height: 15px;
`;

export default Checkbox;
