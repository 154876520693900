import { createGlobalStyle, DefaultTheme  } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${p => p.theme.dark2};
  }

  body, button, select {
    color: ${p => p.theme.textColor};
  }

  option {
    color: black;
  }

  a {
    color: ${p => p.theme.link};
  }

  a:hover {
    color: ${p => p.theme.linkHover};
  }
`;

export type ThemeType = typeof theme;
const theme: DefaultTheme  = {
  primary: '#3AA16D',
  primaryDark: '#328059',
  dark1: '#11161D',
  dark2: '#1A1D22',
  dark3: '#1F2227',
  dark4: '#292d33',
  darkHover: '#44484E',
  textColor: '#EEEEEE',
  textMuted: '#CCCCCC',
  // link
  link: '#3AA16D',
  linkHover: '#328059',
  // alert
  alert: {
    default: {
      text: '#383D41',
      background: '#E2E3E5',
      border: '#D6D8DB',
    },
    success: {
      text: '#155724',
      background: '#D4EDDA',
      border: '#C3E6CB',
    },
    warning: {
      text: '#856404',
      background: '#FFF3CD',
      border: '#FFEEBA',
    },
    error: {
      text: '#000000',
      background: '#CF6679',
      border: '#000000',
    },
  },
};

export default theme;
