import { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import Api from '../../api';

import Dropdown from '../../components/dropdown';
import Menu from '../../components/Menu';
import IconAction from '../../components/IconAction';
import { HiddenMobile, HiddenDesktop } from '../../components/basics';
import {ReactComponent as CloseIcon} from 'assets/icons/cancel-icon.svg';

import {
  DesktopForm,
  MobileForm,
  InputWrapper,
  TextInput,
  SubmitButton,
  SearchIcon,
  MobileInputContainer,
  MobileInputWrapper,
  CloseButton
} from './Autocomplete.style';

const Autocomplete = ({ streamService }) => (
  <>
    <AutocompleteMobile streamService={streamService} />
    <AutocompleteDesktop streamService={streamService} />
  </>
);

const AutocompleteMobile = ({ streamService }) => {
  const { text, options, onChange, reset, onOptionClick, onSubmit } = useAutocomplete(streamService);
  const [focus, setFocus] = useState(false);

  const submit = useCallback((evt) => {
    if (text !== '') setFocus(false);
    onSubmit(evt);
  }, [text, onSubmit]);

  const optionClick = useCallback((res) => {
    onOptionClick(res);
    setFocus(false);
  }, [onOptionClick]);

  const open = useCallback(() => setFocus(true), []);
  const close = useCallback(() => setFocus(false), []);

  useEffect(() => {
    reset();
  }, [reset, streamService]);

  return (
    <HiddenDesktop>
      <MobileForm onSubmit={submit}>
        {focus ? (
          <MobileInputContainer>
            <MobileInputWrapper>
              <TextInput
                autoFocus
                type='text'
                placeholder='Cerca un anime'
                value={text}
                onChange={onChange}
              />
              <SubmitButton><SearchIcon height='20' /></SubmitButton>
            </MobileInputWrapper>
            <CloseButton onClick={close}>
              <CloseIcon fill='white' width='24' height='24' />
            </CloseButton>
            <div style={{ position: 'absolute', left: 0, right: 0, bottom: 0 }}>
              <Dropdown visible={true}>
                <Menu
                  onSelect={optionClick}
                  options={options}
                  minWidth='100%'
                />
              </Dropdown>
            </div>
          </MobileInputContainer>
        ) : (
          <button onClick={open}>
            <IconAction icon={SearchIcon} iconProps={{ height: '19', color: 'white' }} />
          </button>
        )}
      </MobileForm>
    </HiddenDesktop>
  );
};

const AutocompleteDesktop = ({ streamService }) => {
  const { text, options, onChange, onSubmit, onOptionClick } = useAutocomplete(streamService);
  const [focused, setFocused] = useState(false);
  const containerRef = useRef();

  const onFocus = useCallback(() => setFocused(true), []);

  useEffect(() => {
    const onDocumentClick = (e) => {
      if (!containerRef.current.contains(e.target)) setFocused(false);
    };

    if (focused === true) {
      document.body.addEventListener('click', onDocumentClick);
    }
    return () => document.body.removeEventListener('click', onDocumentClick);
  }, [focused]);

  return (
    <HiddenMobile>
      <DesktopForm onSubmit={onSubmit}>
        <InputWrapper onFocus={onFocus} ref={containerRef}>
          <TextInput
            type='text'
            placeholder='Cerca un anime'
            value={text}
            onChange={onChange}
          />
          <SubmitButton><SearchIcon height='20' /></SubmitButton>
        </InputWrapper>

        <Dropdown visible={focused}>
          <Menu
            onSelect={onOptionClick}
            options={options}
            minWidth='100%'
          />
        </Dropdown>
      </DesktopForm>
    </HiddenMobile>
  );
};

function useAutocomplete(streamService) {
  const navigate = useNavigate();
  const [text, setText] = useState('');
  const [results, setResults] = useState({ time: new Date().getTime(), data: [] });
  const [loading, setLoading] = useState(false);
  const options = useMemo(() => {
    return results.data.map((res) => ({ label: res.title, value: res }));
  }, [results]);
  const [debounced] = useDebounce(text, 500, { maxWait: 500 });

  const fetchResults = useCallback(async (searchValue) => {
    const currTime = new Date().getTime();
    setLoading(true);
    try {
      const data = await Api.autocomplete(streamService.id, searchValue);
      setResults(oldResults => {
        if (currTime < oldResults.time) return oldResults;
        return { time: currTime, data };
      })
    } finally {
      setLoading(false);
    }
  }, [streamService]);

  useEffect(() => {
    if (debounced !== '') fetchResults(debounced);
  }, [debounced, fetchResults]);

  const onChange = useCallback(({ target: { value } }) => {
    setText(value);
    if (value === '') {
      setResults({ time: new Date().getTime(), data: [] });
      setLoading(false);
    }
  }, []);

  const reset = useCallback(() => {
    setText('');
    setResults({ time: new Date().getTime(), data: [] });
    setLoading(false);
  }, []);

  const onSubmit = useCallback(async (evt) => {
    evt.preventDefault();
    reset();
    document.activeElement.blur();
    navigate({
      pathname: `/search/anime/${streamService.id}`,
      search: `?title=${text}`,
    });
  }, [streamService, text, reset, navigate]);

  const onOptionClick = useCallback((res) => {
    navigate(`/anime/${streamService.id}/${res.remote_id}`);
    reset();
  }, [reset, streamService, navigate]);

  return { text, options, onChange, loading, reset, onSubmit, onOptionClick };
}

export default Autocomplete;
