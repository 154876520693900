const actions = {
  LOGOUT: 0,
  OPEN_DOWNLOAD_MANAGER: 1,
  CHANGE_SERVICE: 2,
  LAST_EPISODES: 3,
  LAST_REQUESTED: 4,
  ADVANCED_SEARCH: 5,
};

export default actions;
